import { retriable } from '~/apis/timeeApi/retriable';
import { aspidaClient } from '~/categories/admin/apis/utils/aspidaClient';
import {
  BusinessFactCheckEvidenceRejectReasonType,
  BusinessFactCheckEvidenceType,
  BusinessFactCheckRequest,
  BusinessFactCheckStatus,
  BusinessFactCheckDetail,
  BusinessFactCheckSummary,
  InstantBusinessFactCheckSummary,
  InstantBusinessFactCheckStatus,
  InstantBusinessFactCheckRequest,
  InstantBusinessFactCheckDetail,
} from '~/categories/admin/models/types/businessEvidence';
import { PaginationInfo } from '~/models/types/common';

export type IBusinessFactCheckApi = {
  fetchBusinessFactChecks(params: { type: BusinessFactCheckStatus; page: number }): Promise<{
    results: BusinessFactCheckSummary[];
    pagination: PaginationInfo;
  }>;
  fetchBusinessFactCheck(params: { businessFactCheckId: number }): Promise<BusinessFactCheckDetail>;
  fetchBusinessFactCheckRejectReasonTypes(): Promise<BusinessFactCheckEvidenceRejectReasonType[]>;
  fetchBusinessFactCheckEvidenceTypes(): Promise<BusinessFactCheckEvidenceType[]>;
  judgeBusinessFactCheck(params: BusinessFactCheckRequest): Promise<void>;

  // インスタント実態確認
  fetchInstantBusinessFactChecks(params: {
    type: InstantBusinessFactCheckStatus;
    page: number;
  }): Promise<{
    results: InstantBusinessFactCheckSummary[];
    pagination: PaginationInfo;
  }>;
  fetchInstantBusinessFactCheck(params: {
    instantBusinessFactCheckSubmissionId: number;
  }): Promise<InstantBusinessFactCheckDetail>;
  judgeInstantBusinessFactCheck(params: InstantBusinessFactCheckRequest): Promise<void>;
};

export const businessFactCheckApi: IBusinessFactCheckApi = {
  fetchBusinessFactChecks: (params) => {
    return retriable(
      async () =>
        await aspidaClient.api.v1.business_fact_checks.$get({
          query: { status: params.type, page: params.page },
        }),
    );
  },
  fetchBusinessFactCheck: (params) => {
    return retriable(
      async () =>
        await aspidaClient.api.v1.business_fact_checks
          ._business_fact_check_id(params.businessFactCheckId)
          .$get(),
    );
  },
  fetchBusinessFactCheckRejectReasonTypes: () => {
    return retriable(
      async () =>
        await aspidaClient.api.v1.master.business_fact_check.evidence.reject_reason_types.$get(),
    );
  },
  fetchBusinessFactCheckEvidenceTypes: () => {
    return retriable(
      async () => await aspidaClient.api.v1.master.business_fact_check.evidence.types.$get(),
    );
  },
  judgeBusinessFactCheck: (params) => {
    return aspidaClient.api.v1.business_fact_checks
      ._business_fact_check_id(params.businessFactCheckId)
      .$patch({
        body: {
          evidence: params.evidence,
        },
      });
  },
  fetchInstantBusinessFactChecks: (params) => {
    return retriable(
      async () =>
        await aspidaClient.api.v1.instant_business_fact_check.submissions.$get({
          query: { status: params.type, page: params.page },
        }),
    );
  },
  fetchInstantBusinessFactCheck: (params) => {
    return retriable(
      async () =>
        await aspidaClient.api.v1.instant_business_fact_check.submissions
          ._submission_id(params.instantBusinessFactCheckSubmissionId)
          .$get(),
    );
  },
  judgeInstantBusinessFactCheck: (params) => {
    return aspidaClient.api.v1.instant_business_fact_check.submissions
      ._submission_id(params.instantBusinessFactCheckSubmissionId)
      .$patch({
        body: {
          evidence: params.evidence,
        },
      });
  },
};
